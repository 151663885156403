@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.loading__gantt {
    width: 100%;
    height: 100%;

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.gantt {
    &__main {
        display: flex;
        flex-direction: column;
        // display: grid;
        // grid-template-rows: 1fr auto;
        // row-gap: 20px;

        &__content {
            &__gantt--empty {
                height: 300px;
                border: 2px dashed $blue-500;

                h2 {
                    color: $blue-500
                }
            }

            &__introduccion {
                background: linear-gradient(135deg, $blue-500 0%, $blue-300 100%);
                background-repeat: no-repeat;
                padding: 20px;
                position: relative;

                &__img {
                    img {
                        width: 90%;
                    }
                }
            } 

            &__grafica {
                display: block;
                position: relative;
                width: 100%;
                background-color: #fff;
                padding: 20px;
                overflow-x: scroll;

                .btn__expandir {
                    position: absolute;
                    right: 30px;
                    bottom: 40px;
                    transition: all .2s ease-in-out;
                    border: 2px solid $blue-500;
                    border-radius: 10px;
                    padding: 3px 8px !important;
                    background-color: #fff;

                    i {
                        color: $blue-500;
                        font-size: 20px;
                    }
                }

                .btn__expandir:hover {
                    transform: scale(1.1);
                    border: 2px solid $blue-500;
                }
            }
        }

        &__aside {
            &--empty {
                background: linear-gradient(135deg, #0d6efd 0%, #6ea8fe 100%);
                background-repeat: no-repeat;
                padding: 20px;

                img {
                    align-self: flex-end;
                    max-width: 100%;
                    max-height: 160px;
                }
            }

            &__graficas {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                .doughnut__grafica {
                    width: 100%;
                    padding: 20px;
                    margin-bottom: 20px;

                    &__info {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        
                        &__textos {
                            h4 {
                                color: $blue-500;
                            }

                            p {
                                font-size: 14px;
                            }

                            p:first-child {
                                color: $blue-500;
                            }
                        }

                        &__chart {
                            width: 120px;
                            height: 120px;
                        }
                    }
                }
                .doughnut__grafica:last-child {
                    margin-bottom: 0;
                }
            }

            &__actividad {
                height: 500px;
                overflow-y: scroll;
                padding: 20px;
                background-color: #fff;

                h3 {
                    color: $blue-500;
                }

                ul {
                    list-style: none;
                    li {
                        font-size: 14px;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                        span {
                            color: $blue-500;
                        }
                    }

                    li:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            
        }
    }

}

@media screen and (min-width: 768px) {
    .gantt {
        // height: 100%;
        height: calc(100% - 40px);
        
        &__main {
            display: grid;
            grid-template-columns: 50% auto;
            grid-template-rows: auto;
            height: calc(100% - 48px);
            row-gap: 0;
            column-gap: 20px;

            &__content {
                height: 100%;

                &__gantt--empty {
                    height: calc(100% - 174px);
                }

                &__introduccion {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    height: 150px;
                    padding: 30px;

                    // &__textos {
                    //     width: calc(100% - 260px);
                    // }

                    &__img {    
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        padding-right: 30px;
                        padding-bottom: 30px;
                        
                        img {
                            width: 210px;
                        }
                    }
                }

                &__grafica {
                    overflow-x: auto
                }
            }

            &__aside {
                &--empty {
                    height: fit-content;
                    padding: 30px;
                }

                &__graficas {
                    .doughnut__grafica {
                        padding: 30px;
                    }
                }

                &__actividad {
                    height: fit-content;
                    padding: 30px;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .gantt {
        &__main {
            grid-template-columns: 70% auto;
        }
    }
}