@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.loading__areas {
    width: 100%;
    padding-top: 40px;

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.error__areas {
    height: calc(100% - 48px);
    border: 2px dashed $danger;
}

.areas {
    &__empty {
        height: calc(100% - 48px);
        border: 2px dashed $blue-500;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    
        &__area {
            background-color: #fff;
            padding: 10px !important;
            width: calc(50% - 5px);
            height: 80px;
    
            h4 {
                color: $blue-500;
            }
        }
    
        &__area:hover {
            background-color: $blue-500 !important;
    
            h4 {
                color: #fff;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .areas {
        &__list {
            height: calc(100% - 48px);
            
            &__area {
                padding: 20px !important;
                width: calc(20% - 10px);
                height: 100px;
        
                h4 {
                    color: $blue-500;
                }
            }
        }
    }
}