@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.img__error {
    width: 200px;
    max-width: 100%;
}

.scroll--y {
    overflow-y: scroll;
    padding-right: 30px;
}

.listaAreas__okr--container {
    height: calc(100% - 48px);

    .listaAreas__okr {
        height: calc(100% - 62px);
    }
}