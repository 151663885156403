@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.not__found {
    width: 100%;
    height: 100%;

    img {
        width: 200px;
        max-width: 100%;
    }

    h2 {
        color: $blue-500
    }

    a {
        border-radius: 20px;
        // width: 180px;
        height: 40px;
        background-color: $blue-500;
        color: #fff;
    }

    a:hover {
        background-color: #fff;
        color: $blue-500;
    }
}

@media screen and (min-width: 768px) {
    .not__found {
        height: calc(100% - 40px)
    }
}