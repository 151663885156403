@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.areas__proyectos--empty, .areas__proyectos--error {
    height: calc(100% - 48px);

    img {
        width: 200px;
        max-width: 100%;
    }
}

.loading__proyectos {
    width: 100%;
    height: calc(100% - 48px);

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.proyectos {
    // height: 100%;
    height: calc(100% - 40px);

    // Mensajes de error al hacer fetch
    &__error {
        height: calc(100% - 48px);

        img {
            width: 200px;
            max-width: 100%;
        }

        h2 {
            color: $blue-500
        }
    }

    // Cuando la URL no encuentra un id existente
    &--null {
        width: 100%;
        height: 100%;

        img {
            width: 200px;
            max-width: 100%;
        }
    
        h2 {
            color: $blue-500
        }
    
        a {
            border-radius: 20px;
            height: 40px;
            background-color: $blue-500;
            color: #fff;
        }
    
        a:hover {
            background-color: #fff;
            color: $blue-500;
        }
    }

    &__dashboards {
        .img__error {
            width: 200px;
            max-width: 100%;
        }

        .table__custom {
            width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 14px;

            &__header {
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid $gray-300;
            }
        
            &__body {
                display: flex;
                flex-direction: column;
            }
        
            &__row {
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-bottom: 1px solid $gray-300;
                cursor: pointer;
            }

            &__row:nth-last-child(odd){
                background-color: $gray-100;
            }

            &__row:nth-last-child(even){
                background-color: $gray-200;
            }

            &__row:hover {
                background-color: $blue-500;
                color: #fff !important;
            }

            &__cell {
                white-space: nowrap;
                padding: 6px 10px;
        
                &--title {
                    color: $blue-500;
                }
            }

            .cell__id {
                width: 40px;
            }

            .cell__name {
                width: 200px;
            }
        }
    }

    &__main {
        &__menu {
            width: 100%;

            .container__accordion {
                width: 100%;

                .btn__accordion.selected {
                    i, span {
                        color: $blue-500;
                        font-weight: 500;
                    }
                }

                .btn__accordion.unselected {
                    i, span {
                        color: #000;
                        font-weight: normal;
                    }
                }

                .acordion {
                    border-radius: 0;
                }
        
                .accordion-item {
                    border: none;

                    .accordion-header {
                        .accordion-button {
                            font-size: 14px;
                            padding: 10px 0;
                            height: 40px;
                            color: $blue-500;
                            box-shadow: none;
                        }

                        .accordion-button[aria-expanded="true"] {
                            background-color: rgba($color: #fff, $alpha: 0);
                        }
                    }
                }
                
                .accordion-body {
                    padding: 10px 0;
        
                    button {
                        width: 100%;
                        padding: 10px 0;
                        font-size: 14px;
                        text-align: left;
                        color: #000;
        
                        .active {
                            display: none
                        }
                    }
        
                    button:last-child {
                        margin-bottom: 0;
                    }
        
                    button:hover .active {
                        display: block;
                    }
                }
            }
    
            &__btn {
                width: 100%;
                height: 40px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .proyectos {
        &__dashboards {
            height: calc(100% - 48px)
        }

        &__main {
            // height: 100%;
            height: calc(100% - 48px);

            &__menu {
                width: 210px;
                margin-right: 20px;

                .container__accordion {
                    overflow-x: hidden;
                    overflow-y: scroll;
                    height: calc(100% - 40px);

                    .accordion {
                        width: 190px;
                    }
                }

                .container__accordion::-webkit-scrollbar {
                    width: 10px;
                }
        
                .container__accordion::-webkit-scrollbar-track {
                    background-color: rgba($color: $gray-300, $alpha: 0);
                }
        
                .container__accordion::-webkit-scrollbar-thumb {
                    background-color: $gray-500;
                    border-radius: 5px;
                }

                &__btn {
                    width: 180px;
                    height: 40px;
                }
            }

            &__menu--close {
                width: 0px;
                margin-right: 0px;
                transition: width 0.1s;
            }
        }
    }
}