@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.loading__listaEmpresasOkr {
    width: 100%;
    height: calc(100% - 48px);

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.listaEmpresas__main--error {
    height: calc(100% - 48px);
    border: 2px dashed $danger;

    img {
        width: 200px;
        max-width: 100%;
    }
}

.listaEmpresas__main--empty {
    height: calc(100% - 48px);
    border: 2px dashed $blue-500;

    img {
        width: 200px;
        max-width: 100%;
    }
}

.listaEmpresas__main {
    height: calc(100% - 48px);
    
    .table__custom__emp {
        display: flex;
        flex-direction: column;
        font-size: 14px;

        &__header {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #dee2e6;
        }

        &__body {
            display: flex;
            flex-direction: column;
        }

        &__row {
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid $gray-300;
        }

        &__cell {
            white-space: nowrap;
            padding: 6px 10px;

            &--title {
                color: $blue-500;
            }

            .btn__edit--icon {
                color: $success
            }

            .btn__delete--icon {
                color: $danger
            }
        }
    }
}