@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.table__custom__emp {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    &__header {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #dee2e6;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__row {
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $gray-300;
    }

    &__cell {
        white-space: nowrap;
        padding: 6px 10px;

        &--title {
            color: $blue-500;
        }

        .btn__edit--icon {
            color: $success
        }

        .btn__delete--icon {
            color: $danger
        }
    }
}