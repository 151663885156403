@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.areas__main__container {
    height: calc(100% - 62px);
    overflow-y: scroll;
    padding-right: 30px;
}

.areas {
    height: calc(100% - 40px);

    &__main {
        .scroll--y {
            overflow-y: scroll;
            padding-right: 30px;
        }

        height: calc(100% - 48px);
        // overflow-y: scroll;
    }
}