@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.loading__powerbi {
    width: 100%;
    height: 100%;

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.no__access {
    width: 100%;
    height: 100%;

    img {
        width: 200px;
        max-width: 100%;
    }

    h2 {
        color: $blue-500
    }

    a {
        border-radius: 20px;
        width: 100px;
        height: 40px;
        background-color: $blue-500;
        color: #fff;
    }

    a:hover {
        background-color: #fff;
        color: $blue-500;
    }
}

.powerBi {
    width: 100%;
    height: calc(100% - 40px);
}