@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.dropdowm__info {
    a {
        color: #212529;
    }
    .dropdown-item a:hover {
        color: $blue-500;
    }
    .dropdown-item:hover {
        color: $blue-500;
    }
    .dropdown-item:active {
        background-color: #fff;
    }
}

.dropdown-toggle::after {
    display: none !important;
}

.navbar {
    width: 100%;
    padding: 20px !important;

    &__content {
        button {
            img {
                width: 36px;
                height: 36px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .navbar {
        padding: 20px 40px !important;
    
        &__content {
            a {
                img {
                    height: 40px;
                }
            }

            .btn {
                p {
                    color: $blue-500;
                }
            }
        }
    }
}


