@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.profile {
    img {
        width: 40px;
        height: 40px;
    }

    h3 {
        color: $blue-500;
    }
}

@media screen and (min-width: 768px){
    .profile {
        img {
            width: 80px;
            height: 80px;
        }
    }
}