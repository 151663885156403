@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.loading__reset {
    background: rgba($color: #0D6EFD, $alpha: 0.7);

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: #fff;
        }
    }
    
}

.reset__password {
    background: linear-gradient(135deg, $blue-500 0%, $blue-300 100%);
    background-repeat: no-repeat;

    &__container {
        height: auto;
        width: 400px;
        max-width: 90%;
        transition: width 0.5s;

        &__form {
            padding: 20px;

            label {
                color: $blue-500;
            }

            .form__error {
                font-size: 12px;
                color: $danger;
                margin: 0;
            }

            &__title {
                i {
                    font-size: 24px;
                    line-height: normal;
                    color: $blue-500;
                }
        
                h3 {
                    color: $blue-500;
                }
            }

            form {
                .input--fake {
                    border: 1px solid #dee2e6;
                    border-radius: 6px;
                }
                .input--none:focus {
                    border: none !important;
                    box-shadow: none;
                }
                .input--icon {
                    padding-right: 12px;

                    i {
                        color: $blue-500;
                    }
                }
            }
    
            &__img {
                width: 160px;
            }
        }
    }

    
}

@media screen and (min-width: 768px) {
    .reset__password {
        &__container {
            height: 75%;
            width: 800px;
            max-width: 90%;
            transition: width 0.5s;

            &__form {
                padding: 40px;
            }

            &__info {
                background-color: $gray-100;
                padding: 40px;

                img {
                    width: 200px;
                    max-width: 100%;
                }

                i {
                    font-size: 24px;
                    line-height: normal;
                    color: $blue-500;
                }
        
                h3 {
                    color: $blue-500;
                }
            }
        }
        
    }
}