@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.formDataIn__error {
    font-size: 12px;
    color: $danger;
    margin: 0;
}

.formDatIn {
    // width: 100%;
    height: calc(100% - 56px);
    overflow-y: scroll;

    &__main {
        width: 100%;
    }
}

.steps__container {
    &__main {
        width: 100%;
    }
}

.steps {
    &__step {
        &__circle {
            width: 32px;
            height: 32px;
            border: 2px solid $blue-500;
            transition-property: all;
            transition-timing-function: linear;
            transition-duration: 10ms;

        }

        &__circle.active {
            border: none;
            background-color: $blue-500;
            color: #fff;
        }
    }

    &__line {
        width: 2px;
        height: 20px;
        background-color: $gray-300;
        transition-property: all;
        transition-timing-function: linear;
        transition-duration: 10ms;
    }

    &__line.active {
        background-color: $blue-500;
    }
}

.steps:last-child .steps__line {
    display: none;
}

.form__datos {
    label {
        font-size: 14px;
    }

    .icon__dato {
        cursor: pointer;
        font-size: 12px;
    }
}

.form__log {
    label {
        font-size: 14px;
    }
}

.preview {
    &__title {
        h2 { color: $blue-500 }

        i { cursor: pointer }
    }

    &__logs {
        &__lista {
            &__log {
                width: 240px;
                // height: 90px;
                padding: 10px;
                margin-bottom: 8px;

                img {
                    width: 20px;
                    height: 20px;
                }

                i {
                    color: $blue-500;
                }
            }

            &__log:last-child {
                margin-bottom: 0;
            }
        }

        &__grafica {
            width: 100%;

            &__container {
                width: 90%;
            }
        }
    }
}

.final__step {
    img {
        width: 150px;
        max-width: 100%;
    }

    h2 {
        color: $blue-500
    }
}

@media screen and (min-width: 768px) {
    .formDatIn {
        height: calc(100% - 72px);
    }
    .steps {
        &__line {
            width: auto;
            height: auto;
            border: 2px solid $gray-300;
        }

        &__line.active {
            border: 2px solid $blue-500;
        }
    }

    .preview {
        &__logs {
            &__grafica {
                width: calc(100% - 264px);

                &__container {
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .steps__container {
        &__main {
            width: 75%;
        }
    }

    .formDatIn {
        &__main {
            width: 75%;
        }
    }
}
