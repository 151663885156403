@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.modal__delete {
    .modal-header {
        .modal-title {
            button {
                padding: 0;
                width: 24px;
                height: 24px;
                border: none;
            }
        }
    }
}

.okrDetalle {
    // overflow: hidden;
    height: calc(100% - 40px);

    &__main {
        height: calc(100% - 40px);

        .scroll--y {
            padding-right: 30px;
        }

        &__info {
            height: 100%;
            overflow-y: scroll;

            &__title {
                .btn__edit, .btn__delete {
                    width: 32px;
                    height: 32px;
                    padding: 0;
                }
            }

            .table__custom__body {
                display: flex;
                flex-direction: column;

                .table__custom__row {
                    height: 50px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-bottom: 1px solid $gray-300;
                    font-size: 14px;

                    .table__custom__cell {
                        white-space: nowrap;
                        padding: 6px 10px;

                        .pill__kr {
                            font-size: 12px;
                            font-weight: 500;
                            background-color: $blue-500;
                            padding: 0 12px;
                        }                
                    }

                    .cell__prioridad {
                        // span {
                        //     padding: 6px 12px;
                        // }

                        &--baja {
                            background-color: $blue-500 !important;
                        }

                        &--media {
                            background-color: $blue-600 !important;
                        }

                        &--alta {
                            background-color: $blue-700 !important;
                        }
                    }

                    .cell__progreso {
                        width: 160px;
                    }

                    .cell__responsable {
                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            .comentarios__container {
                .comentarios--empty {
                    height: 120px;
                    border: 2px dashed #0d6efd;
                }

                .comentarios {
                    margin-bottom: 16px;

                    img {
                        width: 24px;
                        height: 24px;
                    }
    
                    &__textos {
                        .text-md {
                            font-size: 14px;
                        }
    
                        .text-xs {
                            font-size: 12px;
                        }
                    }
                }

                .comentarios:last-child {
                    margin-bottom: 0;
                }
            }

            .addComentario {
                .comentarios--error {
                    font-size: 12px;
                }
            }
        }

        &__extras {
            .avatar__aside {
                width: 24px;
                height: 24px;
            }

            .badge--baja {
                background-color: $blue-500 !important;
            }

            .badge--media {
                background-color: $blue-600 !important;
            }

            .badge--alta {
                background-color: $blue-700 !important;
            }
        }
    }
}

@media screen and (min-width: 768px) {

}

@media screen and (min-width: 992px) {
    .okrDetalle {
        &__main {
            display: grid;
            grid-template-columns: auto 240px;
            // grid-template-rows: max-content;
            column-gap: 30px;
        }
    }
}