@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.loading__datin {
    width: 100%;
    height: 100%;

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.error__datin {
    height: calc(100% - 48px);
    border: 2px dashed $danger;
}

.datIN__dashboards {
    height: calc(100% - 48px);

    .table__custom {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;

        &__header {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $gray-300;
        }
    
        &__body {
            display: flex;
            flex-direction: column;
        }
    
        &__row {
            height: 50px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid $gray-300;
            cursor: pointer;
        }

        &__row:nth-last-child(odd){
            background-color: $gray-100;
        }

        &__row:nth-last-child(even){
            background-color: $gray-200;
        }

        &__row:hover {
            background-color: $blue-500;
            color: #fff !important;
        }

        &__cell {
            white-space: nowrap;
            padding: 6px 10px;
    
            &--title {
                color: $blue-500;
            }
        }

        .cell__id {
            width: 40px;
        }

        .cell__name {
            width: 200px;
        }
    }
}

.datIN__consultoras {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    &__consultora {
        cursor: pointer;
        background-color: #fff;
        padding: 10px !important;
        width: calc(50% - 5px);
        height: 80px;

        h4 {
            color: $blue-500;
        }
    }

    &__consultora:hover {
        background-color: $blue-500 !important;

        h4 {
            color: #fff;
        }
    }
}

@media screen and (min-width: 768px) {
    .datIN__consultoras {
        &__consultora {
            padding: 20px !important;
            height: 100px;  
        }
    }
}

@media screen and (min-width: 992px) {
    .datIN__consultoras {
        &__consultora {
            width: calc(20% - 10px);
        }
    }
}