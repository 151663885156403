@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.preview__logs__lista__log {
    position: relative;

    &__btn {
        display: none;
        position: absolute;
        flex-direction: column;
        top: 0;
        right: 0;

        button {
            width: 36px;
            height: 36px;
            padding: 0;
        }
    }
}

.preview__logs__lista__log:hover .active {
    display: flex;
}

.form__metrica {
    form {
        .form__input {
            width: 100%;
        }

        .btn__metrica {
            width: 180px;
        }
    }
}

@media screen and (min-width: 768px)  {
    .form__metrica {
        form {
            .form__input {
                width: calc(100% - 180px);
            }
        }
    }
}