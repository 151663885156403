@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.loading__ver {
    width: 100%;
    height: 100%;

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.modal__ver {
    &__nombre {
        color: $blue-500;
    }

    &__info {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &__prioridad {
            span {
                padding: 6px 12px;
            }
        
            &--baja {
                background-color: $blue-500 !important;
            }
        
            &--media {
                background-color: $blue-600 !important;
            }
        
            &--alta {
                background-color: $blue-700 !important;
            }
        } 
    
        &__estado {
            span {
                padding: 6px 12px;
            }
    
            &--pendiente {
                background-color: $gray-500 !important;
            }
    
            &--proceso {
                background-color: $warning !important;
            }
    
            &--completada {
                background-color: $success !important;
            }
    
            &--espera {
                background-color: $purple-400 !important;
            }
    
            &--cancelada {
                background-color: $danger !important;
            }
    
            &--bloqueada {
                background-color: $black !important;
            }
        }

        &__notas {
            border: 2px solid $blue-100;
            padding: 20px;

            h4 {
                color: $blue-500;
            }
        }
    } 
}
