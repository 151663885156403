@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.modal-header {
    border: none !important;
    
    .modal-title {
        width: 100%;
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between;

        h3 {
            color: $blue-500;
        }

        button {
            padding: 0;
            width: 24px;
            height: 24px;
            border: none;

            i {
                font-size: 24px;
                color: $gray-600;
                line-height: normal;
            }
        }
    }
}

.modal-body {
    padding: 0 20px 20px 20px !important;
}

.form__tarea {
    label {
        font-size: 14px;
    }

    &sub__btn {
        width: 200px;
        height: 40px;
    }
    
    &__btn {
        width: 160px;
        height: 40px;
    }

    &__error {
        font-size: 12px;
        color: $danger;
        margin: 0;
    }

    &__progressBar {
        button {
            width: 20px;
            height: 20px;
        }

        &__bar {
            width: calc(100% - 60px);
        }
    }
}