@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.scroll--y {
    overflow-y: scroll;
}

.addHito {
    p { font-size: 14px;}
    span {font-size: 12px;}
    
    &__btn {
        width: 24px;
        height: 24px;
    
        i {
            font-size: 16px;
        }
    }
}

.lista__hitos {
    list-style-type: none;
    max-height: 180px;

    li {
        font-size: 14px;
        word-break: break-all;
    }

    .btn__delete__hito {
        width: 20px;
        height: 20px;

        i {
            font-size: 12px;
        }
    }

    .texto__hito {
        width: calc(100% - 28px);
    }
}


.modal__okr {
    .modal-header {
        border: none !important;

        .modal-title {
            width: 100%;
            display: flex !important;
            flex-direction: row !important;
            justify-content: space-between;

            h3 {
                color: $blue-500;
            }
    
            button {
                padding: 0;
                width: 24px;
                height: 24px;
                border: none;
    
                i {
                    font-size: 24px;
                    color: $gray-600;
                    line-height: normal;
                }
            }
        }
    }

    .modal-body {
        padding: 0 20px 20px 20px !important;

        .form__okr {
            label {
                font-size: 14px;
            }

            &__progressBar {
                width: calc(100% - 60px);
            }

            &__btnProgress {
                width: 20px;
                height: 20px;
            }

            &__error {
                font-size: 12px;
                color: $danger;
                margin: 0;
            }
        }
    }
}