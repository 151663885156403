@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.loading__listaOkr {
    width: 100%;

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: $blue-500;
        }
    }

    p {
        color: $blue-500
    }
}

.okr__error {
    height: 200px;
    border: 2px dashed $danger;

    img {
        width: 100px;
        max-width: 100%;
    }
}

.okr--empty {
    height: 200px;
    border: 2px dashed $blue-500;
}

.okr {
    height: calc(100% - 40px);

    &__title {
        .btn__newOkr {
            width: 150px;
        }
    }

    .scroll--y {
        overflow-y: scroll;
        padding-right: 30px;
    }

    &__main {
        height: calc(100% - 110px);

        &__personales {

        }
    
        &__areas {
            
        }
    
        &__keyresults {
            .table__custom__body {
                display: flex;
                flex-direction: column;
    
                .table__custom__row {
                    height: 50px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-bottom: 1px solid $gray-300;
                    font-size: 14px;
    
                    .table__custom__cell {
                        white-space: nowrap;
                        padding: 6px 10px;
    
                        .pill__kr {
                            font-size: 12px;
                            font-weight: 500;
                            background-color: $blue-500;
                            padding: 0 12px;
                        }                
                    }
    
                    .cell__prioridad {
                        width: 80px;
    
                        &--baja {
                            background-color: $blue-500 !important;
                        }
    
                        &--media {
                            background-color: $blue-600 !important;
                        }
    
                        &--alta {
                            background-color: $blue-700 !important;
                        }
                    }
    
                    .cell__progreso {
                        width: 160px;
                    }
    
                    .cell__responsable {
                        width: 150px;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    
}