@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap/scss/_variables.scss';

.loading {
    background: rgba($color: #0D6EFD, $alpha: 0.7);

    div {
        margin-bottom: 10px;
        svg g g circle[stroke] {
            stroke: #fff;
        }
    }
    
}

.login {
    background: linear-gradient(135deg, $blue-500 0%, $blue-300 100%);
    background-repeat: no-repeat;

    &__container {
        margin: 0 auto;
        height: 75%;
        width: 400px;
        max-width: 90%;
        transition: width 0.5s;

        &__form {
            padding: 40px;

            label {
                color: $blue-500;
            }

            .form__error {
                font-size: 12px;
                color: $danger;
                margin: 0;
            }

            &__img {
                width: 160px;
            }
        }

        &__info {
            background-color: $gray-100;
            padding: 40px;

            &__title {
                color: $blue-500;
                font-weight: $font-weight-semibold;
            }

            &__subtitle {
                color: $gray-700;
                font-weight: $font-weight-semibold;
            }

            img {
                width: 200px;
                max-width: 80%;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    
    .login {
        &__container {
            margin: 0 auto;
            height: 75%;
            width: 800px;
            max-width: 90%;
            transition: width 0.5s;
        }
        
    }
}